import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  avatar: { marginRight: theme.spacing(1) },
  logo: {
    height: 56,
    marginRight: 'auto',
  },
  appBar: {
    background: 'transparent',
    marginTop: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    textTransform: 'none',
  },
  list: {
    minWidth: 250,
  },
  drawerContent: {
    paddingTop: theme.spacing(4),
    maxWidth: 260,
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(0.5),
  },
  avatarIcon: {
    fontSize: theme.typography.pxToRem(20),
    marginLeft: theme.spacing(1),
  },
  drawerName: {
    wordBreak: 'break-word',
  },
}));
