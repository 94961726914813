import React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import AppToolbar from 'ea-components/AppToolbar';
import { Footer } from 'ea-components/Footer';
import { useHistory } from 'react-router';

import { useStyles } from './styles';

export default function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  const handleBackClick = () => history.goBack();

  return (
    <>
      <AppToolbar />
      <Container maxWidth="md">
        <Box textAlign="center">
          <Typography variant="h4" component="h1" className={classes.title}>
            404
          </Typography>
          <Typography variant="h6" component="h2" className={classes.subTitle}>
            We couldn&apos;t find the page you are looking for
          </Typography>

          <Typography variant="body1" gutterBottom className={classes.message}>
            The page may of been moved or no longer exists
          </Typography>

          <Button color="primary" variant="outlined" onClick={handleBackClick}>
            Back
          </Button>
        </Box>
      </Container>
      <Footer className={classes.footer} />
    </>
  );
}
