import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  avatar: { marginRight: theme.spacing(1) },
  logo: {
    height: 56,
    marginRight: 'auto',
  },
  appBar: {
    background: 'transparent',
    marginTop: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    textTransform: 'none',
  },
  divider: {
    height: 20,
    alignSelf: 'center',
    margin: theme.spacing(0, 2),
  },
  list: {
    minWidth: 250,
  },
  drawerContent: {
    paddingTop: theme.spacing(4),
  },
}));
