import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import {
  Application,
  Subscription as ApiSubscription,
} from 'ea-account-api/types';
import React from 'react';
import {
  ExpirationStatus,
  getSubscriptionExpiryStatus,
  isSubscriptionActive,
} from '../../app/store/applications/selectors';
import { useStyles } from './styles';
import { expirationColors, getSubscriptionStatusMessage } from './utils';

const { WEBSITE_URL } = process.env;

export interface ApplicationDetails {
  logo: any;
  description: string;
  code: string;
  subscribeUrl: string;
}

interface SubscriptionProps {
  application?: Application;
  appDetails?: ApplicationDetails;
  subscription?: ApiSubscription;
  isLoading: boolean;
}

export const Subscription = ({
  application,
  appDetails,
  subscription,
  isLoading = false,
}: SubscriptionProps) => {
  const classes = useStyles();
  const hasActiveSubscription = Boolean(
    subscription && isSubscriptionActive(subscription)
  );
  const hasSubscription = Boolean(subscription);

  const expiryStatus = subscription
    ? getSubscriptionExpiryStatus(subscription)
    : ExpirationStatus.None;

  const getHref = () => {
    if (hasActiveSubscription) {
      return application?.url;
    }

    if (hasSubscription && expiryStatus === ExpirationStatus.Expired) {
      return `${WEBSITE_URL}/contact-us`;
    }

    return appDetails?.subscribeUrl;
  };

  const getButtonText = () => {
    if (hasActiveSubscription) {
      return 'Open';
    }
    if (hasSubscription && expiryStatus === ExpirationStatus.Expired) {
      return 'Contact us';
    }

    return 'Learn more';
  };

  const renderStatusMessage = () => {
    if (!subscription || expiryStatus === ExpirationStatus.None) {
      return null;
    }

    if (!hasActiveSubscription) {
      if (expiryStatus !== ExpirationStatus.Expired) {
        return null;
      }
    }

    const message = getSubscriptionStatusMessage(subscription, expiryStatus);

    return (
      <Alert
        className={classes.expirationBanner}
        severity={expirationColors[expiryStatus]}
        icon={<FontAwesomeIcon icon={faClock} />}
        aria-label={message}
      >
        {message}
      </Alert>
    );
  };

  if (!isLoading && !appDetails) {
    return null;
  }

  const linkProps: unknown =
    application && !isLoading
      ? {
          component: 'a',
          href: getHref(),
          target: '_blank',
        }
      : {};

  return (
    <Card
      elevation={0}
      className={classes.card}
      data-testid="subscription-btn"
      {...linkProps}
    >
      <CardContent className={classes.content}>
        <div className={classes.logoContainer}>
          {isLoading || !application ? (
            <>
              <Skeleton variant="rect" className={classes.titleSkeleton} />
              <Skeleton variant="rect" className={classes.logoSkeleton} />
            </>
          ) : (
            <>
              <Typography variant="h6" className={classes.title}>
                {appDetails?.code}
              </Typography>

              <img src={appDetails?.logo} alt="logo" className={classes.logo} />
            </>
          )}
        </div>
        <Box className={classes.contentBody}>
          {isLoading || !application ? (
            <Box pt={2} mb={1}>
              <Skeleton height={14} style={{ marginBottom: 6 }} />
              <Skeleton height={14} width="80%" style={{ marginBottom: 8 }} />
            </Box>
          ) : (
            <>
              {renderStatusMessage()}
              <Typography className={classes.description} variant="body2">
                {appDetails?.description}
              </Typography>
            </>
          )}
          <div className={classes.btnContainer}>
            {isLoading || !application ? (
              <>
                <Divider flexItem className={classes.divider} />
                <Skeleton variant="rect" className={classes.skeletonBtn} />
              </>
            ) : (
              <>
                <Divider flexItem className={classes.divider} />
                <Typography
                  variant="button"
                  data-testid="subscription-btn-text"
                  className={classes.actionText}
                >
                  {getButtonText()}
                </Typography>
              </>
            )}
          </div>
        </Box>
        <div className={classes.foot} />
      </CardContent>
    </Card>
  );
};
