import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardContent,
  Drawer,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { UserMenuList } from '../Menu';
import { useStyles } from '../styles';
import { UserMenuProps } from '../types';

export const MobileMenu = ({
  claims,
  isLoading = false,
  isDrawerVisible,
  onMenuItemClick,
  onClose,
  onOpen,
}: UserMenuProps) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <Skeleton variant="rect" height={24} width={24} style={{ margin: 8 }} />
    );
  }

  if (claims?.email) {
    return (
      <>
        <IconButton onClick={onOpen} color="primary" data-test-id="user-menu">
          <FontAwesomeIcon icon={faBars} />
        </IconButton>
        <Drawer anchor="right" open={isDrawerVisible} onClose={onClose}>
          <Card>
            <CardContent className={classes.drawerContent}>
              {claims && (
                <>
                  <Typography variant="body2">Welcome</Typography>
                  <Typography variant="h6" className={classes.drawerName}>
                    {claims.name}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
          <UserMenuList onMenuItemClick={onMenuItemClick} />
        </Drawer>
      </>
    );
  }

  return null;
};
