import {
  AuthError,
  EventType,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { forgottenPasswordRequest, loginRequest } from './config';
import { useUser } from '../store/user';

/**
 * Houses the handling of the global events
 */
export function useMsalEvents(msalInstance: IPublicClientApplication) {
  const [error, setError] = useState<Error | undefined>();
  const { getUser } = useUser();

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    const errorHandlerCallbackId = msalInstance.addEventCallback(message => {
      switch (message.eventType) {
        case EventType.LOGIN_FAILURE:
          if (message.error instanceof AuthError) {
            // Reset password error
            if (message.error.errorMessage.startsWith('AADB2C90118')) {
              msalInstance.loginRedirect(forgottenPasswordRequest);
              return;
            }

            // User cancelled : The user has cancelled entering self-asserted information.
            if (message.error.errorMessage.startsWith('AADB2C90091')) {
              msalInstance.loginRedirect(loginRequest);
              return;
            }
          }
          break;
        case EventType.LOGIN_SUCCESS:
          // Reload user details after a successful login
          getUser();
          break;
        default:
          break;
      }

      if (message.error) {
        setError(message.error);
      }
    });

    return () => {
      if (errorHandlerCallbackId) {
        msalInstance.removeEventCallback(errorHandlerCallbackId);
      }
    };
  }, [msalInstance]);

  return {
    error,
  };
}
