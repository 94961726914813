import { createSlice } from '@reduxjs/toolkit';
import { Application } from 'ea-account-api/types';
import { LoadableState } from '../types';
import { fetchApplications } from './thunks';

interface ApplicationSlice extends LoadableState {
  applications: Application[] | undefined;
}

export const initialState: ApplicationSlice = {
  applications: undefined,
  loading: false,
  error: undefined,
};

export const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchApplications.pending, state => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchApplications.fulfilled, (state, action) => {
      state.loading = false;
      state.applications = action.payload;
    });
    builder.addCase(fetchApplications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ?? action.error;
    });
  },
});

export const { reducer, actions } = applicationSlice;
