import {
  Configuration,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

const { TENANT } = process.env;
const { NODE_ENV } = process.env;
const { CLIENT_ID } = process.env;

const policies = {
  signIn: 'B2C_1A_SignIn',
  passwordReset: 'B2C_1A_ResetPassword',
  changePassword: 'B2C_1A_ChangePassword',
};

const scopes = [
  'openid',
  `https://${TENANT}.onmicrosoft.com/accountportal/user_impersonation`,
];

const authority =
  NODE_ENV === 'production'
    ? `https://auth.eatechnology.com/${TENANT}.onmicrosoft.com`
    : `https://${TENANT}.b2clogin.com/tfp/${TENANT}.onmicrosoft.com`;

const signInAuthority = `${authority}/${policies.signIn}`;

export const msalConfig: Configuration = {
  auth: {
    authority: signInAuthority,
    clientId: CLIENT_ID!,
    knownAuthorities: [signInAuthority],
  },
};

export const loginRequest: RedirectRequest = {
  scopes,
};

export const forgottenPasswordRequest: RedirectRequest = {
  scopes,
  authority: `${authority}/${policies.passwordReset}`,
};

export const changePasswordRequest: RedirectRequest = {
  scopes,
  authority: `${authority}/${policies.changePassword}`,
};

export const silentTokenRequest: SilentRequest = {
  scopes,
};

export const createSilentTokenRequest = (account): SilentRequest => ({
  scopes,
  account,
});
