import { createAsyncThunk } from '@reduxjs/toolkit';
import { SaveUser } from 'ea-account-api/types';
import { accountApi } from '../../api';
import { adaptSubscriptions } from './adapters';

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, api) => {
  try {
    const me = await accountApi.fetchMe();

    if (me?.subscriptions) {
      me.subscriptions = adaptSubscriptions(me.subscriptions);
    }
    return me;
  } catch (err) {
    return api.rejectWithValue({ message: err.message });
  }
});

export const save = createAsyncThunk(
  'user/save',
  async (user: SaveUser, api) => {
    try {
      await accountApi.saveMe(user);
      return user;
    } catch (err) {
      return api.rejectWithValue({ message: err.message });
    }
  }
);
