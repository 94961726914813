import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import { Box, Container, Link, Typography } from '@material-ui/core';
import AppToolbar from 'ea-components/AppToolbar';
import { Footer } from 'ea-components/Footer';
import { SubscriptionGrid } from 'ea-components/SubscriptionGrid';
import React, { useEffect } from 'react';
import { useStore } from 'react-redux';
import ViseaLogo from '../../../assets/visea logo.svg';
import { loginRequest } from '../../auth/config';
import { selectors, useApplications } from '../../store/applications';
import { useUser } from '../../store/user';
import { useStyles } from './styles';

const { WEBSITE_URL } = process.env;

export default function Dashboard() {
  const isAuthenticated = useIsAuthenticated();
  const { getUser, loading: isUserLoading, user } = useUser();
  const { getApplications, error: applicationsError } = useApplications();
  const { getState } = useStore();
  const classes = useStyles();

  const [
    subscribedApplications,
    nonSubscribedApplications,
  ] = selectors.getApplicationSubscriptions(getState());

  const subscriptions = user?.subscriptions ?? [];
  const hasSubscriptions = !isUserLoading && subscribedApplications.length > 0;

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await Promise.all([getApplications(), getUser()]);
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (applicationsError) {
      throw applicationsError.message;
    }
  }, [applicationsError]);

  return (
    <>
      <AppToolbar loginRequest={loginRequest} />
      <Container maxWidth="md">
        <MsalAuthenticationTemplate
          // Returning null to prevent template bubbling error internally
          errorComponent={() => null}
          interactionType={InteractionType.Redirect}
        >
          <Box textAlign="center">
            <Typography variant="h4" component="h1" gutterBottom>
              Welcome
            </Typography>

            <Typography variant="h6" component="h2" className={classes.heading}>
              {isUserLoading || hasSubscriptions
                ? 'You have the following active subscriptions:'
                : 'It looks like you have no subscriptions, here is what you are missing out on:'}
            </Typography>
          </Box>
          {(isUserLoading || hasSubscriptions) && (
            <SubscriptionGrid
              isLoading={isUserLoading}
              applications={subscribedApplications}
              subscriptions={subscriptions}
            />
          )}
          {isUserLoading ||
            (Boolean(nonSubscribedApplications.length) && (
              <>
                {hasSubscriptions && (
                  <Box textAlign="center" mt={hasSubscriptions ? 4 : 0}>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.heading}
                    >
                      You are missing out on:
                    </Typography>
                  </Box>
                )}
                <SubscriptionGrid
                  isLoading={isUserLoading}
                  subscriptions={subscriptions}
                  applications={nonSubscribedApplications}
                />
              </>
            ))}
          <Box textAlign="center" my={4}>
            <Container maxWidth="sm">
              <img
                src={ViseaLogo}
                alt="Visea logo"
                className={classes.viseaLogo}
              />
              <Typography variant="body1">
                The visea family of software products provide robust,
                easy-to-use solutions to the problems that network operators
                face every day.
                <br />
                <br />
                To find out more{' '}
                <Link
                  variant="body1"
                  color="secondary"
                  href={`${WEBSITE_URL}/resources/brochures/software-solutions/ea-technology-visea/`}
                  target="_blank"
                >
                  click here
                </Link>
              </Typography>
            </Container>
          </Box>
        </MsalAuthenticationTemplate>
      </Container>
      <Footer className={classes.footer} />
    </>
  );
}
