import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AppToolbar from 'ea-components/AppToolbar';
import { Footer } from 'ea-components/Footer';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { changePasswordRequest, loginRequest } from '../../auth/config';
import { useCurrentAccount } from '../../auth/useCurrentAccount';
import { useUser } from '../../store/user';
import { useStyles } from './styles';

export default function Profile() {
  const { claims } = useCurrentAccount();
  const { instance } = useMsal();
  const classes = useStyles();
  const { user, getUser, saving, saveUser, error } = useUser();
  const [mfaEnabled, setMfaEnabled] = useState(false);

  const handle2faChange = () => setMfaEnabled(!mfaEnabled);

  const handleChangePasswordClick = () =>
    instance.loginRedirect(changePasswordRequest);

  const handleSignoutClick = () => instance.logoutRedirect();

  const handleSecuritySubmit = async e => {
    e.preventDefault();

    await saveUser({
      use2FA: mfaEnabled,
    });
  };

  useEffect(() => {
    (async () => {
      if (!user) {
        await getUser();
      }
    })();

    setMfaEnabled(user?.use2FA ?? false);
  }, [user]);

  return (
    <>
      <AppToolbar loginRequest={loginRequest} />
      <Container maxWidth="md">
        <MsalAuthenticationTemplate
          // Returning null to prevent template bubbling error internally
          errorComponent={() => null}
          interactionType={InteractionType.Redirect}
        >
          <Box mt={2}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              data-test-id="breadcrumbs"
            >
              <MuiLink component={Link} color="inherit" to="/">
                Home
              </MuiLink>
              <Typography color="secondary">Manage Profile</Typography>
            </Breadcrumbs>
          </Box>
          <Box mt={2}>
            <div className={classes.profileContainer}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon={faUser} className={classes.avatarIcon} />
              </Avatar>
              <div className={classes.profileName}>
                <Typography variant="h6" component="h2">
                  {claims?.name}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  {claims?.email}
                </Typography>
              </div>
            </div>
            <Divider />
            <div className={classes.settingRow}>
              <div className={classes.settingLabel}>
                <Typography variant="body1">Account</Typography>
              </div>
              <div className={classes.accountBtns}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={handleChangePasswordClick}
                  data-test-id="change-pwd-btn"
                >
                  Change password
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={handleSignoutClick}
                  data-test-id="signout-btn"
                >
                  Logout
                </Button>
              </div>
            </div>
            <div className={classes.settingRow}>
              <div className={classes.settingLabel}>
                <Typography variant="body1">Security</Typography>
              </div>
              <div>
                <form onSubmit={handleSecuritySubmit}>
                  <Typography variant="body1" gutterBottom>
                    {/* Make sure this text is updated in the 2fa template to */}
                    Add an extra layer of security to your account by adding two
                    factor authentication (Recommended)
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={mfaEnabled}
                        onChange={handle2faChange}
                        name="checkedF"
                      />
                    }
                    label="Enable two factor authentication"
                  />
                  <Box mt={2} display="flex">
                    <Button
                      color="primary"
                      disabled={saving}
                      variant="contained"
                      size="large"
                      className={classes.btnPrimary}
                      disableElevation
                      type="submit"
                    >
                      {saving ? 'Saving...' : 'Save'}
                    </Button>
                    {error && (
                      <Box ml={2}>
                        <Alert variant="standard" severity="error">
                          There was an issue saving your settings
                        </Alert>
                      </Box>
                    )}
                  </Box>
                </form>
              </div>
            </div>
          </Box>
        </MsalAuthenticationTemplate>
      </Container>
      <Footer className={classes.footer} />
    </>
  );
}
