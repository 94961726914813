import { makeStyles } from '@material-ui/core';
import theme from '../../../themes';

export const useStyles = makeStyles(() => ({
  title: {
    marginTop: theme.spacing(4),
    fontSize: theme.typography.pxToRem(122),
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  footer: {
    marginTop: 'auto',
  },
}));
