import { createMuiTheme } from '@material-ui/core';
import { TTHoves, TTHovesMedium } from './fonts';

const theme = createMuiTheme({
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: '"TT Hoves", "Roboto", "Helvetica", Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#220639',
      '400': '#59446b',
      '300': '#91839c',
      '200': '#c8c1ce',
      '100': '#e9e6eb',
    },
    secondary: {
      main: '#00aae4',
      '400': '#40bfeb',
      '300': '#80d4f1',
      '200': '#bfeaf8',
      '100': '#e5f7fc',
      light: '#e5f7fc',
    },
    grey: {
      '500': '#4a4a49',
      '400': '#777777',
      '300': '#a4a4a4',
      '200': '#d2d2d2',
      '100': '#ededed',
    },
    text: {
      primary: '#220639',
    },
    error: {
      main: '#e51e3d',
    },
    warning: {
      main: '#fab721',
    },
    success: {
      main: '#1c9d5b',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const mediumFont =
  '"TT Hoves Medium", "Roboto", "Helvetica", Arial, sans-serif';

const overrides: typeof theme.overrides = {
  MuiTypography: {
    button: {
      fontSize: theme.typography.pxToRem(16),
    },
    body1: {
      fontSize: theme.typography.pxToRem(16),
    },
    body2: {
      fontSize: theme.typography.pxToRem(15),
    },
    h6: {
      fontFamily: mediumFont,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.3,
    },
    h5: {
      fontFamily: mediumFont,
    },
    h4: {
      fontFamily: mediumFont,
    },
    h3: {
      fontFamily: mediumFont,
    },
    h2: {
      fontFamily: mediumFont,
    },
    h1: {
      fontFamily: mediumFont,
    },
  },
  MuiCssBaseline: {
    '@global': {
      '@font-face': [TTHoves, TTHovesMedium],
      body: {
        backgroundColor: '#fff',
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      },
    },
  },
  MuiInput: {
    input: {
      '&::placeholder': {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiButton: {
    outlined: {
      borderRadius: 24,
      paddingLeft: 24,
      paddingRight: 24,
      textTransform: 'none',
    },
    outlinedPrimary: {
      '&:hover, &:active, &:focus': {
        color: theme.palette.common.white,
        backgroundColor: `${theme.palette.primary.main}!important`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  MuiBreadcrumbs: {
    root: {},
    separator: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    li: {
      fontSize: 14,
      color: theme.palette.text.primary,
      '& > p': {
        fontWeight: 500,
        fontSize: 14,
        fontFamily: mediumFont,
      },
    },
  },
};

theme.overrides = overrides;

export default theme;
