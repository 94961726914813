import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useCurrentAccount } from '../../app/auth/useCurrentAccount';
import { AppToolbar } from './AppToolbar';

const loadingStatuses = [
  InteractionStatus.Startup,
  InteractionStatus.Login,
  InteractionStatus.Logout,
];

export default function container(props) {
  const { instance, inProgress } = useMsal();
  const { account, claims } = useCurrentAccount();

  const isLoading = loadingStatuses.indexOf(inProgress) > -1 || !account;

  const handleLogout = () => instance.logoutRedirect();

  return (
    <AppToolbar
      {...props}
      claims={claims}
      isLoading={isLoading}
      onLogout={handleLogout}
    />
  );
}
