import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

/**
 * Explicit sign out page to be called by b2c single sign out
 */
export default function SignOutPage() {
  const { instance } = useMsal();
  useEffect(() => {
    instance.logout({ postLogoutRedirectUri: window.location.origin });
  });
  return null;
}
