export class FetchServerError extends Error {
  statusCode: number;

  statusText: string;

  constructor(response: Response) {
    super('Error performing fetch');

    this.statusCode = response.status;
    this.statusText = response.statusText;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchServerError);
    }
  }
}
