import { useAppDispatch, useAppSelector } from '../utils';
import { fetchApplications } from './thunks';

export const useApplications = () => {
  const state = useAppSelector(root => root.application);
  const dispatch = useAppDispatch();
  const getApplications = () => dispatch(fetchApplications());

  return {
    ...state,
    getApplications,
  };
};
