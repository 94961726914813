import { createSlice } from '@reduxjs/toolkit';
import { User } from 'ea-account-api/types';
import { LoadableState } from '../types';
import { fetchUser, save } from './thunks';

interface UserSlice extends LoadableState {
  user: User | undefined;
  saving: boolean;
}

export const initialState: UserSlice = {
  user: undefined,
  loading: false,
  saving: false,
  error: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUser.pending, state => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload ?? action.error;
    });
    builder.addCase(save.pending, state => {
      state.saving = true;
      state.error = undefined;
    });
    builder.addCase(save.fulfilled, (state, action) => {
      state.saving = false;
      state.user = { ...state.user!, ...action.payload };
    });
    builder.addCase(save.rejected, (state, action) => {
      state.saving = false;
      state.error = action.payload ?? action.error;
    });
  },
});

export const { reducer, actions } = userSlice;
