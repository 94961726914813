import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useRef } from 'react';
import { UserMenuList } from '../Menu';
import { useStyles } from '../styles';
import { UserMenuProps } from '../types';

export const DesktopMenu = ({
  claims,
  isLoading = false,
  isDrawerVisible,
  onMenuItemClick,
  onClose,
  onOpen,
}: UserMenuProps) => {
  const classes = useStyles();
  const anchorEl = useRef<HTMLButtonElement>(null);

  if (isLoading) {
    return <Skeleton variant="rect" height={20} width={120} />;
  }

  if (claims?.name) {
    return (
      <>
        <Button
          ref={anchorEl}
          key="123"
          color="primary"
          variant="text"
          onClick={onOpen}
          className={classes.btn}
          data-test-id="user-menu"
        >
          {claims.name}
          <FontAwesomeIcon icon={faUser} className={classes.avatarIcon} />
        </Button>
        <Popover
          open={isDrawerVisible}
          anchorEl={() => anchorEl.current!}
          elevation={2}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <UserMenuList onMenuItemClick={onMenuItemClick} />
        </Popover>
      </>
    );
  }

  return null;
};
