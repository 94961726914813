import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 0),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  copyright: {
    lineHeight: 2,
  },
  links: {
    display: 'flex',
    marginLeft: 'auto',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },

    '& a': {
      lineHeight: 2,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
      },
    },
  },
}));
