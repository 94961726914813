import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export function ResponsiveComponent({ Component, DesktopComponent }) {
  const ResponsiveComponent = props => {
    const { children, ...restProps } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    if (isMobile) {
      return <Component {...restProps}>{children}</Component>;
    }
    return <DesktopComponent {...restProps}>{children}</DesktopComponent>;
  };
  ResponsiveComponent.displayName = `Responsive(${getDisplayName(Component)})`;
  return ResponsiveComponent;
}
