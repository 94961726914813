import { SaveUser } from 'ea-account-api/types';
import { useAppDispatch, useAppSelector } from '../utils';
import { fetchUser, save } from './thunks';

export const useUser = () => {
  const state = useAppSelector(root => root.user);
  const dispatch = useAppDispatch();
  const getUser = () => dispatch(fetchUser());
  const saveUser = (user: SaveUser) => dispatch(save(user));

  return {
    ...state,
    getUser,
    saveUser,
  };
};
