import { useAccount, useMsal } from '@azure/msal-react';
import { IdTokenClaims } from './types';

/**
 * Self contains any functionality/properties relating to the b2c users current account
 */
export function useCurrentAccount() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const claims = account?.idTokenClaims as IdTokenClaims | undefined;

  return { claims, account };
}
