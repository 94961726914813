import { ResponsiveComponent } from 'ea-components/ResponsiveComponent';
import React, { useState } from 'react';
import { IdTokenClaims } from '../../app/auth/types';
import { DesktopMenu } from './desktop/DesktopMenu';
import { UserMenuListItemNames } from './Menu';
import { MobileMenu } from './mobile/MobileMenu';

interface UserMenuProps {
  claims?: IdTokenClaims | undefined;
  isLoading: boolean;
  onLogout: () => void;
}

const ResponsiveMenu = ResponsiveComponent({
  Component: MobileMenu,
  DesktopComponent: DesktopMenu,
});

export const UserMenu = ({
  claims,
  isLoading = false,
  onLogout,
}: UserMenuProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleDrawerMenuBtnClick = () => setDrawerVisible(true);
  const handleDrawerClose = () => setDrawerVisible(false);

  const handleMenuItemClick = (name: UserMenuListItemNames) => {
    switch (name) {
      case 'signout':
        setDrawerVisible(false);
        onLogout();
        break;
      default:
        break;
    }
  };

  return (
    <ResponsiveMenu
      claims={claims}
      isLoading={isLoading}
      isDrawerVisible={drawerVisible}
      onOpen={handleDrawerMenuBtnClick}
      onClose={handleDrawerClose}
      onMenuItemClick={handleMenuItemClick}
    />
  );
};
