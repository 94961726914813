import {
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { createSilentTokenRequest, loginRequest, msalConfig } from './config';

export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Houses behaviour to retrieve the access token for the signed in user
 * @returns accessToken on success if the user is signed else undefined.
 *
 * Causes a redirect on error to perform an interactive re-acquire of the token
 */
export async function getAccessToken(
  instance: IPublicClientApplication
): Promise<string | undefined> {
  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error('User must be signed in');
  }

  try {
    const authResult = await instance.acquireTokenSilent(
      createSilentTokenRequest(activeAccount ?? accounts[0])
    );
    return authResult.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return undefined;
    }
    throw error;
  }
}
