import { Application, Subscription } from 'ea-account-api/types';
import {
  differenceInDays,
  differenceInSeconds,
  isSameOrAfter,
  isSameOrBefore,
} from 'ea-utils/dateTimeUtils';
import { RootState } from '..';

export enum ExpirationStatus {
  None = 'None',
  Expired = 'Expired',
  Expiring = 'Expiring',
  ExpiringSoon = 'ExpiringSoon',
  GracePeriod = 'GracePeriod',
}

export const expiryPeriods = {
  /**
   * Number of days until subscription status is considered 'Expiring' by end date
   */
  expiringFromDays: 28,
  /**
   * Number of days until subscription status is considered 'Expiring soon' by end date
   */
  expiringSoonFromDays: 14,
};

function findSubscriptionByApplication(
  applicationId: string,
  subscriptions: Subscription[]
) {
  return subscriptions.find(sub => sub.applicationID === applicationId);
}

export function isSubscriptionActive(subscription: Subscription): Boolean {
  if (!subscription.startDateTime) {
    return false;
  }

  // Check normal date periods
  if (
    isSameOrBefore(subscription.startDateTime) &&
    (!subscription.endDateTime || isSameOrAfter(subscription.endDateTime))
  ) {
    return true;
  }

  // Check if a grace period is in place and in range
  if (
    subscription.gracePeriodEnd &&
    isSameOrBefore(subscription.startDateTime) &&
    isSameOrAfter(subscription.gracePeriodEnd)
  ) {
    return true;
  }

  return false;
}

export function getApplicationSubscriptions(
  state: RootState
): [Application[], Application[]] {
  if (!state.application.applications?.length) {
    return [[], []];
  }

  return state.application.applications.reduce(
    (acc, app) => {
      const subscription = findSubscriptionByApplication(
        app.id,
        state.user.user?.subscriptions ?? []
      );

      if (subscription && isSubscriptionActive(subscription)) {
        acc[0].push(app);
      } else {
        acc[1].push(app);
      }

      return acc;
    },
    [[], []] as [Application[], Application[]]
  );
}

export function getSubscriptionExpiryStatus(
  subscription: Subscription
): ExpirationStatus {
  if (!subscription?.startDateTime || !subscription.endDateTime) {
    return ExpirationStatus.None;
  }

  const daysDifference = differenceInDays(subscription.endDateTime);
  if (daysDifference > expiryPeriods.expiringFromDays) {
    return ExpirationStatus.None;
  }

  if (daysDifference > expiryPeriods.expiringSoonFromDays) {
    return ExpirationStatus.Expiring;
  }

  if (daysDifference > 0) {
    return ExpirationStatus.ExpiringSoon;
  }

  const secondsDifference = differenceInSeconds(subscription.endDateTime);
  if (secondsDifference >= 0) {
    return ExpirationStatus.ExpiringSoon;
  }

  if (subscription.gracePeriodEnd) {
    const gracePeriodTimeDifference = differenceInSeconds(
      subscription.gracePeriodEnd
    );
    if (gracePeriodTimeDifference >= 0) {
      return ExpirationStatus.GracePeriod;
    }
  }

  return ExpirationStatus.Expired;
}
