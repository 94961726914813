import { faKey, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

export type UserMenuListItemNames = 'signout';

interface UserMenuListProps {
  onMenuItemClick: (name: UserMenuListItemNames) => void;
}

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />;
}

export const UserMenuList = ({ onMenuItemClick }: UserMenuListProps) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItemLink to="/" data-test-id="menu-subscriptions">
        <ListItemIcon className={classes.icon}>
          <FontAwesomeIcon icon={faKey} />
        </ListItemIcon>
        <ListItemText primary="Subscriptions" />
      </ListItemLink>
      <ListItemLink to="/profile" data-test-id="menu-profile">
        <ListItemIcon className={classes.icon}>
          <FontAwesomeIcon icon={faUser} />
        </ListItemIcon>
        <ListItemText primary="Manage Profile" />
      </ListItemLink>
      <ListItem
        button
        data-test-id="menu-logout"
        onClick={() => onMenuItemClick('signout')}
      >
        <ListItemIcon className={classes.icon}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );
};
