import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: 'auto',
  },
  viseaLogo: {
    width: 70,
    marginBottom: theme.spacing(1),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));
