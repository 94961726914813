import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  subscriptionContainer: {
    maxWidth: 960,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    margin: theme.spacing(0, 'auto', 2, 'auto'),
  },
}));
