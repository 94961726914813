import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    height: '100%',
    overflow: 'visible',
    boxShadow: `0px 6px 15px 0px ${theme.palette.grey['200']}`,
    textDecoration: 'none',
    transition: theme.transitions.create(['border', 'box-shadow'], {
      easing: 'ease-in',
      duration: 200,
    }),

    '&:hover, &:active, &:focus': {
      boxShadow: `0px 6px 15px 0px ${theme.palette.grey['300']}`,

      '& $actionText': {
        textDecoration: 'underline',
      },
    },
  },
  title: {
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(22),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '100%',
  },
  contentBody: {
    flex: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    margin: theme.spacing(0, -2, 0, -2),
    padding: theme.spacing(2, 2, 0, 2),
  },
  logo: {
    height: 38,
    marginLeft: 'auto',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleSkeleton: {
    height: 20,
    width: 100,
    padding: theme.spacing(0.5, 0),
  },
  logoSkeleton: {
    height: 30,
    width: 40,
    padding: theme.spacing(0.5, 0),
    marginLeft: 'auto',
  },
  btnContainer: {
    marginTop: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonBtn: {
    height: 20,
    width: 80,
    margin: theme.spacing(0.5, 0),
  },
  actionText: {
    textTransform: 'none',
  },
  expirationBanner: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    height: 1,
  },
  foot: {
    height: 6,
    margin: theme.spacing(2, -2, -3, -2),
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  },
}));
