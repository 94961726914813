import React from 'react';
import {
  Box,
  Typography,
  Container,
  withStyles,
  Button,
} from '@material-ui/core';
import { Footer } from 'ea-components/Footer';
import AppToolbar from 'ea-components/AppToolbar';
import { useStyles } from './styles';

function inDev() {
  return process.env.NODE_ENV === 'development';
}

class ErrorBoundary extends React.Component<
  {
    classes: Record<string, string>;
  },
  { error: Error | null }
> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  handleBackClick = () => {
    window.history.back();
  };

  render() {
    const { classes, children } = this.props;
    const { error } = this.state;
    if (error) {
      const err = error! as Error;
      return (
        <>
          <AppToolbar />
          <Container maxWidth="md">
            <Box textAlign="center">
              <Typography
                color="error"
                variant="h4"
                component="h1"
                gutterBottom
                className={classes.title}
              >
                Sorry, something went wrong
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className={classes.subTitle}
                gutterBottom
              >
                Please try again or go back using the back button in your web
                browser
              </Typography>

              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleBackClick}
              >
                Back
              </Button>

              {inDev() && (
                <Box mt={4}>
                  <Typography variant="body1">{err.toString()}</Typography>
                </Box>
              )}
            </Box>
          </Container>
          <Footer className={classes.footer} />
        </>
      );
    }

    return children;
  }
}

export default withStyles(useStyles)(ErrorBoundary);
