import { Box, Container, Link, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useStyles } from './styles';

const { WEBSITE_URL } = process.env;

interface FooterProps {
  className?: string;
}

export const Footer = ({ className }: FooterProps) => {
  const classes = useStyles();
  return (
    <footer className={classNames(classes.footer, className)}>
      <Container className={classes.container}>
        <Typography variant="body2" className={classes.copyright}>
          © EA Technology {new Date().getFullYear()}
        </Typography>
        <Box className={classes.links}>
          <Link variant="body2" color="inherit" href={WEBSITE_URL}>
            Home
          </Link>
          <Link
            variant="body2"
            color="inherit"
            href={`${WEBSITE_URL}/cookies-privacy-policy/`}
          >
            Cookies & Privacy
          </Link>
          <Link
            variant="body2"
            color="inherit"
            href={`${WEBSITE_URL}/contact-us`}
          >
            Contact us
          </Link>
        </Box>
      </Container>
    </footer>
  );
};
