import { Color } from '@material-ui/lab/Alert';
import { Subscription } from 'ea-account-api/types';
import { fromNow } from 'ea-utils/dateTimeUtils';
import { ExpirationStatus } from '../../app/store/applications/selectors';

type Expirations = Record<
  Exclude<ExpirationStatus, ExpirationStatus.None>,
  Color
>;

export const expirationColors: Expirations = {
  Expired: 'error',
  Expiring: 'info',
  ExpiringSoon: 'warning',
  GracePeriod: 'warning',
};

export function getSubscriptionStatusMessage(
  subscription: Subscription,
  expiryStatus: ExpirationStatus
): string {
  if (expiryStatus === ExpirationStatus.GracePeriod) {
    return 'Contact us to renew';
  }

  if (expiryStatus === ExpirationStatus.Expired) {
    return 'Expired';
  }

  return `Expires ${fromNow(subscription.endDateTime!)}`;
}
