import { AppBar, Container, Toolbar } from '@material-ui/core';
import { UserMenu } from 'ea-components/UserMenu';
import React from 'react';
import { IdTokenClaims } from '../../app/auth/types';
import Logo from '../../assets/ea-logo.png';
import { useStyles } from './styles';

interface AppToolbarProps {
  claims?: IdTokenClaims | undefined;
  isLoading: boolean;
  onLogout: () => void;
}

export const AppToolbar = ({
  claims,
  isLoading = false,
  onLogout,
}: AppToolbarProps) => {
  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar} position="relative" elevation={0}>
        <Toolbar>
          <Container className={classes.container}>
            <img src={Logo} alt="EA Technology Logo" className={classes.logo} />
            <UserMenu
              claims={claims}
              isLoading={isLoading}
              onLogout={onLogout}
            />
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};
