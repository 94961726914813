import { Grid } from '@material-ui/core';
import {
  Application,
  Subscription as ApiSubscription,
} from 'ea-account-api/types';
import { Subscription } from 'ea-components/Subscription';
import React from 'react';
import { useStyles } from './styles';
import { getApplicationDetails } from './utils';

interface SubscriptionGridProps {
  applications: Application[];
  subscriptions: ApiSubscription[];
  isLoading: boolean;
}

export const SubscriptionGrid = ({
  applications,
  subscriptions,
  isLoading,
}: SubscriptionGridProps) => {
  const classes = useStyles();

  return (
    <div className={classes.subscriptionContainer}>
      <Grid container spacing={2} justify="center">
        {isLoading
          ? [...Array(3).keys()].map(i => (
              <Grid key={i} item md={4} sm={6} xs={12}>
                <Subscription isLoading />
              </Grid>
            ))
          : applications.reduce((acc, app) => {
              const appDetails = getApplicationDetails(app.name);
              if (!appDetails) {
                return acc;
              }

              const subscription = subscriptions.find(
                sub => sub.applicationID === app.id
              );

              acc.push(
                <Grid key={app.id} item md={4} sm={6} xs={12}>
                  <Subscription
                    application={app}
                    appDetails={appDetails}
                    subscription={subscription}
                    isLoading={false}
                  />
                </Grid>
              );

              return acc;
            }, [] as any)}
      </Grid>
    </div>
  );
};
