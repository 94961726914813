import { Subscription } from 'ea-account-api/types';
import { fromUtc } from 'ea-utils/dateTimeUtils';

/**
 * Takes the subscriptions, and ensures all dates are set as UTC before hitting the store.
 * All subscription dates are currently set as UTC.
 */
export function adaptSubscriptions(
  subscriptions: Subscription[] | undefined
): Subscription[] {
  if (!subscriptions) {
    return [];
  }

  return subscriptions.map(sub => ({
    ...sub,
    startDateTime: fromUtc(sub.startDateTime.toString()),
    endDateTime: sub.endDateTime && fromUtc(sub.endDateTime.toString()),
    gracePeriodEnd:
      sub.gracePeriodEnd && fromUtc(sub.gracePeriodEnd.toString()),
  }));
}
