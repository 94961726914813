import { FetchServerError } from './errors';

export class HttpClient {
  get = async <T>(
    url: string,
    headers: Headers = new Headers()
  ): Promise<T> => {
    const result = await fetch(url, {
      headers,
    });
    return this.handleResponse(result);
  };

  patch = async (
    url: string,
    body: unknown,
    headers: Headers = new Headers()
  ): Promise<void> => {
    headers.append('Content-Type', 'application/json');
    const result = await fetch(url, {
      headers,
      method: 'PATCH',
      body: JSON.stringify(body),
    });

    if (!result.ok) {
      throw new FetchServerError(result);
    }

    return Promise.resolve();
  };

  handleResponse = (resp: Response) => {
    if (!resp.ok) {
      throw new FetchServerError(resp);
    }

    return resp.json();
  };
}
