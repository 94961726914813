import TTHovesMediumWoff2 from '../assets/fonts/TTHoves-Medium.woff2';
import TTHovesRegularWoff2 from '../assets/fonts/TTHoves-Regular.woff2';

export const TTHoves = {
  fontFamily: 'TT Hoves',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 400,
  src: `
    local('TT Hoves'),
    url(${TTHovesRegularWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const TTHovesMedium = {
  fontFamily: 'TT Hoves Medium',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 500,
  src: `
      local('TT Hoves Medium'),
      url(${TTHovesMediumWoff2}) format('woff2')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
