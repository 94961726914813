import mainTheme from '../../../themes';

export const useStyles = (theme: typeof mainTheme) => ({
  title: {
    marginTop: theme.spacing(4),
    fontSize: theme.typography.pxToRem(36),
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
  footer: {
    marginTop: 'auto',
  },
});
