import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { msalInstance } from './auth';
import { useMsalEvents } from './auth/useMsalEvents';
import Dashboard from './pages/dashboard';
import ErrorBoundary from './pages/error/error-boundary';
import NotFound from './pages/not-found/not-found';
import Profile from './pages/profile';
import SignOut from './pages/sign-out/sign-out';

function Routes() {
  useMsalEvents(msalInstance);
  return (
    <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route exact path="/profile">
        <Profile />
      </Route>
      <Route exact path="/sign-out">
        <SignOut />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </BrowserRouter>
  );
}
