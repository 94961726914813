import { ApplicationDetails } from 'ea-components/Subscription/Subscription';
import ConnectLogo from '../../assets/Connect logo.svg';
import DetectLogo from '../../assets/Detect logo.svg';
import InvestLogo from '../../assets/Invest logo.svg';
import PredictLogo from '../../assets/Predict logo.svg';

const { WEBSITE_URL } = process.env;

/**
 * Returns additional data for the application for the component to render
 */
export function getApplicationDetails(
  applicationName: string
): ApplicationDetails | undefined {
  if (!applicationName) {
    return undefined;
  }

  const applicationDetails: ApplicationDetails[] = [
    {
      logo: ConnectLogo,
      description: 'Complete low voltage network design and assessment',
      code: 'connect',
      subscribeUrl: `${WEBSITE_URL}/products/software-solutions/connections-network-design/ea-technology-connect/`,
    },
    {
      logo: DetectLogo,
      description:
        'Complete network visibility with powerful filtering and analytics',
      code: 'detect',
      subscribeUrl: `${WEBSITE_URL}/products/software-solutions/network-monitoring/ea-technology-detect/`,
    },
    {
      logo: InvestLogo,
      description:
        'A complete solution for asset management and investment decision support',
      code: 'invest',
      subscribeUrl: `${WEBSITE_URL}/products/software-solutions/investment-modelling/ea-technology-invest/`,
    },
    {
      logo: PredictLogo,
      description: 'Signalling network capacity both now and into the future',
      code: 'predict',
      subscribeUrl: `${WEBSITE_URL}/`,
    },
  ];

  const details = applicationDetails.find(
    app => applicationName.toLowerCase().indexOf(app.code) > -1
  );

  if (!details) {
    return undefined;
  }

  return details;
}
