import { HttpClient } from './http-client';
import { Application, SaveUser, User } from './types';

export default class AccountApiClient {
  private baseUrl: string;

  private accessTokenCallback: () => Promise<string | undefined>;

  private httpClient: HttpClient;

  constructor(
    baseUrl: string,
    accessTokenCallback: () => Promise<string | undefined>,
    httpClient: HttpClient = new HttpClient()
  ) {
    this.baseUrl = baseUrl;
    this.accessTokenCallback = accessTokenCallback;
    this.httpClient = httpClient;
  }

  createAuthHeader = async () => {
    let accessToken;
    try {
      accessToken = await this.accessTokenCallback();
    } catch (e) {
      throw new Error(`Error retrieving access token: ${e.message}`);
    }

    if (!accessToken) {
      throw new Error('No access token was found');
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append('Authorization', bearer);

    return headers;
  };

  fetchMe = async (): Promise<User | undefined> =>
    this.httpClient.get(
      `${this.baseUrl}/api/me`,
      await this.createAuthHeader()
    );

  saveMe = async (user: SaveUser) =>
    this.httpClient.patch(
      `${this.baseUrl}/api/me`,
      user,
      await this.createAuthHeader()
    );

  fetchApplications = async (): Promise<Application[]> =>
    this.httpClient.get(
      `${this.baseUrl}/api/applications`,
      await this.createAuthHeader()
    );
}
