import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import theme from '../themes';
import { msalInstance } from './auth';
import Router from './router';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <Router />
      </MsalProvider>
    </ThemeProvider>
  );
}

export default hot(App);
