import { createAsyncThunk } from '@reduxjs/toolkit';
import { accountApi } from '../../api';

export const fetchApplications = createAsyncThunk(
  'applications/fetchApplications',
  async (_, api) => {
    try {
      return await accountApi.fetchApplications();
    } catch (err) {
      return api.rejectWithValue({ message: err.message });
    }
  }
);
