import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  profileContainer: {
    display: 'flex',
    margin: theme.spacing(3, 0),
    alignItems: 'center',
    overflow: 'hidden',

    '& > *': {
      wordBreak: 'break-word',
    },
  },
  profileName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatar: {
    height: 50,
    width: 50,
    marginRight: theme.spacing(2),
    background: theme.palette.secondary['100'],

    [theme.breakpoints.up('sm')]: {
      height: 80,
      width: 80,
    },
  },
  avatarIcon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(32),
    },
  },
  accountBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gridGap: theme.spacing(2),
  },
  settingRow: {
    display: 'flex',
    padding: theme.spacing(4, 0),
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  settingLabel: {
    display: 'flex',
    width: 'fit-content',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
      minWidth: 180,
      paddingRight: theme.spacing(8),
    },
    '& > *': {
      fontWeight: 'bold',
    },
  },
  btnPrimary: {
    borderRadius: 24,
    textTransform: 'none',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    alignSelf: 'center',
  },
  footer: {
    marginTop: 'auto',
  },
}));
