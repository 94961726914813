import moment from 'moment';

/**
 * Adds days to the given date
 * @param days Number of days to add (can include negative)
 * @param date The target date
 * @returns The calculated date
 */
export function addDays(days: number, date: Date): Date {
  return moment(date).add(days, 'day').toDate();
}

/**
 * Returns how much time there is between now and the given date
 * @param date The date to calculate from
 * @returns String representing the time difference
 */
export function fromNow(date: Date): string {
  return moment(date).fromNow();
}

/**
 * Returns the number of days difference between 2 dates (defaults to today)
 * @param date1 The date to calculate from
 * @param date2 The second date to calculate with
 * @returns Number of days difference
 */
export function differenceInDays(
  date1: Date,
  date2: Date | undefined = undefined
): Number {
  return moment(date1).diff(date2, 'days');
}

/**
 * Returns the number of seconds difference between 2 dates (defaults to today)
 * @param date1 The date to calculate from
 * @param date2 The second date to calculate with
 * @returns Number of seconds difference
 */
export function differenceInSeconds(
  date1: Date,
  date2: Date | undefined = undefined
): Number {
  return moment(date1).diff(date2, 'seconds');
}

/**
 * Returns boolean indicating if the date is the same or after the current date/time
 * @param date1 The date to calculate from
 */
export function isSameOrAfter(date1: Date): boolean {
  return moment(date1).isSameOrAfter();
}

/**
 * Returns boolean indicating if the date is the same or before the current date/time
 * @param date1 The date to calculate from
 */
export function isSameOrBefore(date1: Date): boolean {
  return moment(date1).isSameOrBefore();
}

/**
 * Uses the passed date as UTC, and returns it as a local date
 * @param date The date to be used as utc
 * @returns New date from UTC in local time
 */
export function fromUtc(date: string): Date {
  return moment.utc(date).toDate();
}
